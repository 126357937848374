import { useContext, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';

import { SiteInfoContext } from '../contexts';
import Logo from './Logo';
import AlertBar from './AlertBar';
import Menus from './Menus';
import SectionWrapper from './SectionWrapper';
import { COLOR_SCHEMES } from '../consts';

const Header = forwardRef(({ fixed, ...others }, ref) => {
  const { header, general } = useContext(SiteInfoContext);
  const { sx } = others;

  return (
    <Box
      ref={ref}
      sx={{ position: fixed ? 'fixed' : 'relative', width: '100%', ...sx }}
    >
      <SectionWrapper
        outerSx={{
          bgcolor: 'parentBlack',
          color: 'parentWhite',
          borderBottom: theme => `1px solid ${theme.palette.darkSlate}`,
        }}
        innerSx={{
          py: 0,
          minHeight: theme => theme.siteSpecs.header.minHeight,
        }}
        innerStackProps={{ direction: 'row', alignItems: 'center' }}
      >
        <Logo
          colorScheme={COLOR_SCHEMES.LIGHT}
          linkToHome
          sx={{ flexShrink: 0 }}
        />
        <Stack
          flexDirection='row'
          alignItems='center'
          justifyContent='flex-end'
          sx={{ flexGrow: 1 }}
        >
          {Array.isArray(header?.menus?.primary) && (
            <Menus items={header.menus.primary} />
          )}
        </Stack>
      </SectionWrapper>
      {general?.siteAlert?.enabled && <AlertBar {...general.siteAlert} />}
    </Box>
  );
});

Header.propTypes = {
  fixed: PropTypes.bool,
};

Header.defaultProps = {
  fixed: true,
};

export default Header;
