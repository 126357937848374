const banner = {
  tagline: 'Open. Extensible. Impactful.',
  content:
    'Welcome to our community! Open Source at Autodesk supports our vision to create a better world designed and made for all.<br>Open Source provides the global community of innovators an opportunity to learn, contribute and grow.',
  links: [
    {
      label: 'Code of Conduct',
      url: '#code-of-conduct',
      external: true,
      target: '_self',
    },
  ],
};

export { banner };
