import aswfLogo from './aswf-logo.svg';

const communities = {
  title: 'We Support and Invest in the Open Source Community',
  textContent:
    'Autodesk is a member and contributor of the following organizations​',
  items: [
    {
      name: 'The Linux Foundation',
      logoUrl: 'https://www.linuxfoundation.org/wp-content/uploads/lf_logo.svg',
      url: 'https://www.linuxfoundation.org/',
    },
    {
      name: 'ASWF',
      logoUrl: aswfLogo,
      url: 'https://www.linuxfoundation.org/',
    },
    {
      name: 'KHRONOS',
      logoUrl:
        'https://www.khronos.org/assets/css/images/khronos-logo-2021.svg',
      url: 'https://www.khronos.org/',
    },
    {
      name: '3MF Consortium',
      logoUrl:
        'https://3mf.io/wp-content/uploads/sites/106/2020/07/3mf-color.svg',
      url: 'https://3mf.io/',
    },
    {
      name: 'W3C',
      logoUrl:
        'https://opensource.autodesk.com/wp-content/uploads/2023/08/w3c-no-bars.svg',
      url: 'https://3mf.io/',
    },
  ],
};

export { communities };
