import axios from 'axios';
import { baseConfig } from '../baseConfig';
import payload from '../payload';

const SERVER_PARAMS = window.OpenSourceConfig;

const REST_URLS = {
  AUTH: '/auth/rest-nonce',
  SETTINGS: '/wp-json/features/v1/settings',
  USER_ME: '/wp-json/wp/v2/users/me',
};

const LOCAL_MODE = process?.env?.REACT_APP_DEV_MODE === 'local';

function formatConfigData(data, baseData) {
  const rt = baseData ? { ...baseData } : { ...baseConfig };
  Object.keys(data).forEach(field => {
    rt[field] = {
      ...baseConfig[field],
      ...data[field],
    };
  });

  if (data.linksHub?.socialMedias) {
    rt.linksHub = {
      ...rt.linksHub,
      socialMedias: {
        ...baseConfig.linksHub.socialMedias,
        ...data.linksHub.socialMedias,
      },
    };
  }

  const blogsLength = baseConfig.blogs.blogs.length;
  if (
    Array.isArray(data.blogs?.blogs) &&
    data.blogs.blogs.length < blogsLength
  ) {
    rt.blogs = {
      ...rt.blogs,
      blogs: [...data.blogs.blogs, ...baseConfig.blogs.blogs].slice(
        0,
        blogsLength
      ),
    };
  }

  rt.currentDateTime = data.timestamp ? new Date(data.timestamp) : new Date();

  return rt;
}

async function getWordPressNonce() {
  return axios({
    url: REST_URLS.AUTH,
    method: 'GET',
    responseType: 'json',
  }).then(response => {
    const { data, status } = response.data;
    if (status && status.success) {
      return Promise.resolve(data.nonce);
    }
    return Promise.reject(response);
  });
}

async function getConfig(forceUpdate) {
  if (LOCAL_MODE) {
    window.console.log('local debug', payload);
    return Promise.resolve(formatConfigData(payload));
  }
  try {
    if (!SERVER_PARAMS || !SERVER_PARAMS['settings'] || forceUpdate) {
      const nonce = await getWordPressNonce();
      return axios({
        url: REST_URLS.SETTINGS,
        method: 'GET',
        headers: { 'X-WP-Nonce': nonce },
        params: {
          keys: [
            'general',
            'header',
            'banner',
            'repos',
            'blogs',
            'codeOfConduct',
            'communities',
            'linksHub',
          ],
        },
      }).then(response => Promise.resolve(formatConfigData(response?.data)));
    }
    return Promise.resolve(formatConfigData(SERVER_PARAMS['settings']));
  } catch (e) {
    return Promise.reject(e);
  }
}

async function updateConfig(data) {
  if (LOCAL_MODE) {
    return Promise.reject('Local mode, cannot save configurations.');
  }
  try {
    const nonce = await getWordPressNonce();
    return axios({
      url: REST_URLS.SETTINGS,
      method: 'POST',
      headers: {
        'X-WP-Nonce': nonce,
        'Content-Type': 'application/json; charset=utf-8',
      },
      data: JSON.stringify(data),
    }).then(response => Promise.resolve(formatConfigData(response?.data)));
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getCurrentUser() {
  const formatData = data => ({
    ...data,
    id: data.id || -1,
    avatar: data.avatar_urls && (data.avatar_urls[96] || data.avatar_urls[48]),
    displayName: data.name || data.slug,
    account: data.slug,
    email: data.email || '',
    isAdmin: data.capabilities && data.capabilities.administrator,
  });

  if (LOCAL_MODE) {
    return Promise.resolve(
      formatData({
        id: 1,
        name: 'Local Debug User',
        capabilities: { administrator: true },
      })
    );
  }

  if (SERVER_PARAMS && SERVER_PARAMS['current_user']) {
    return Promise.resolve(formatData(SERVER_PARAMS['current_user']));
  }

  try {
    const nonce = await getWordPressNonce();
    return axios({
      url: REST_URLS.USER_ME,
      method: 'GET',
      headers: { 'X-WP-Nonce': nonce },
      params: { context: 'edit' },
    }).then(response => Promise.resolve(formatData(response?.data)));
  } catch (e) {
    return Promise.reject(e);
  }
}

export { formatConfigData, getConfig, updateConfig, getCurrentUser };
