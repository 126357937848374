import { useMemo, useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';

import autodeskLogoWhite from '../assets/autodesk-logos/svg/white.svg';
import autodeskLogoBlack from '../assets/autodesk-logos/svg/black.svg';
import { COLOR_SCHEMES } from '../consts';
import { SiteInfoContext } from '../contexts';

export default function Logo({ colorScheme, linkToHome, ...others }) {
  const { general } = useContext(SiteInfoContext);
  const autodeskLogo = useMemo(
    () =>
      colorScheme === COLOR_SCHEMES.LIGHT
        ? autodeskLogoWhite
        : autodeskLogoBlack,
    [colorScheme]
  );
  const wrapperProps = useMemo(
    () => (linkToHome ? { component: Link, to: '/' } : {}),
    [linkToHome]
  );
  const { sx } = others;

  return (
    <Stack
      direction='row'
      alignItems='center'
      spacing={{ xs: 1.5, lg: 2 }}
      sx={{
        boxShadow: 'none',
        outline: 'none',
        textDecoration: 'none',
        ...sx,
      }}
      {...wrapperProps}
    >
      <Box
        component='img'
        src={autodeskLogo}
        alt='Autodesk'
        sx={{ width: { xs: '7.5rem', lg: '8.75rem' } }}
      />
      <Box
        sx={{
          fontFamily: 'ArtifaktElement',
          fontSize: { xs: '1rem', lg: '1.25rem' },
          fontWeight: 'medium',
          whiteSpace: 'nowrap',
          mt: { xs: '1px', lg: 0 },
          color:
            colorScheme === COLOR_SCHEMES.DARK ? 'parentBlack' : 'parentWhite',
        }}
      >
        {general?.siteName || ''}
      </Box>
    </Stack>
  );
}

Logo.propTypes = {
  colorScheme: PropTypes.oneOf(Object.values(COLOR_SCHEMES)),
  linkToHome: PropTypes.bool,
};

Logo.defaultProps = {
  colorScheme: COLOR_SCHEMES.DARK,
  linkToHome: false,
};
