import MaterialX from './materialx-logo.png';
import OpenColorIO from './opencolorio-logo.png';
import USD from './usd-logo.png';
import OTIO from './otio-logo.png';

export const contributionProjects = [
  {
    name: 'Material X',
    description:
      'MaterialX is an open standard for representing rich material and look-development content in computer graphics, enabling its platform-independent description and exchange across applications and renderers.',
    thumb: MaterialX,
    links: [
      {
        label: 'View Project',
        url: 'https://github.com/AcademySoftwareFoundation/MaterialX',
      },
    ],
  },
  {
    name: 'OpenColorIO',
    description:
      'OpenColorIO (OCIO) is a complete color management solution geared towards motion picture production with an emphasis on visual effects and computer animation.',
    thumb: OpenColorIO,
    links: [
      {
        label: 'View Project',
        url: 'https://github.com/AcademySoftwareFoundation/OpenColorIO',
      },
    ],
  },
  {
    name: 'Universal Scene Description',
    description:
      'Universal Scene Description (USD) is an efficient, scalable system for authoring, reading, and streaming time-sampled scene description for interchange between graphics applications.',
    thumb: USD,
    links: [
      {
        label: 'View Project',
        url: 'https://github.com/PixarAnimationStudios/OpenUSD',
      },
    ],
  },
  {
    name: 'OpenTimelineIO',
    description:
      'OpenTimelineIO is an interchange format and API for editorial cut information. OTIO contains information about the order and length of cuts and references to external media.',
    thumb: OTIO,
    links: [
      {
        label: 'View Project',
        url: 'https://github.com/AcademySoftwareFoundation/OpenTimelineIO',
      },
    ],
  },
];
