import { useContext, useEffect } from 'react';
import { SiteInfoContext } from '../contexts';

import NotFoundComponent from '../components/NotFound';
import LinksHub from '../components/LinksHub';

export default function NotFound() {
  const { general, linksHub } = useContext(SiteInfoContext);

  useEffect(() => {
    if (general?.siteName && window?.document) {
      window.document.title = `404 - Autodesk ${general.siteName}`;
    }
  }, [general]);

  if (!general || !linksHub) {
    return null;
  }

  return (
    <>
      <NotFoundComponent />
      <LinksHub {...linksHub} />
    </>
  );
}
