const menus = Object.freeze({
  primary: [
    // {
    //   label: 'Home',
    //   to: '/',
    //   external: false,
    // },
    {
      label: 'Github',
      url: 'https://github.com/Autodesk',
      external: true,
    },
    {
      label: 'Code of Conduct',
      url: '/#code-of-conduct',
      external: true,
      target: '_self',
    },
    {
      label: 'Blogs',
      url: 'https://autodesk.com',
      external: true,
    },
    {
      label: 'Careers',
      url: 'https://www.autodesk.com/careers',
      external: true,
    },
  ],
});

export { menus };
