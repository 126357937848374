import minaImage from './mina.png';
import stellaImage from './stella.png';
import solImage from './sol.png';

const engineers = Object.freeze({
  items: [
    {
      image: minaImage,
      displayName: 'Mina Zhou',
      title: 'Lead Application Security Engineer',
      description:
        'Open Source opens the door for broader learning, sharing, and collaborating. It empowers everyone with the power of people.',
    },
    {
      image: stellaImage,
      displayName: 'Stella Kislyuk',
      title: 'Sr software engineering manager, product delivery platform ',
      description:
        'Open source promotes innovation by allowing developers to build on the success of others, taking innovation further than originally considered or intended.',
    },
    {
      image: solImage,
      displayName: 'Sol Amour',
      title: 'Product Manager for Dynamo',
      description:
        'Embrace open source because you will get out so much more than what you put in. Collectively, as a community, we will all grow and be better versions of our selves.',
    },
  ],
});

export { engineers };
