import { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Link,
  Stack,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from '@mui/material';
import {
  FacebookSharp as FacebookIcon,
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
  LinkedIn as LinkedInIcon,
  ExpandMoreSharp as ExpandIcon,
} from '@mui/icons-material';

import { LinkPropType } from '../propTypes';
import SectionWrapper from './SectionWrapper';

export default function LinksHub({ socialMedias, categories, ...others }) {
  const linkCategories = useMemo(() => {
    const socialMediaItems = [];
    socialMedias?.facebook &&
      socialMediaItems.push({
        icon: FacebookIcon,
        label: 'Facebook',
        url: socialMedias?.facebook,
      });
    socialMedias?.instagram &&
      socialMediaItems.push({
        icon: InstagramIcon,
        label: 'Instagram',
        url: socialMedias?.instagram,
      });
    socialMedias?.twitter &&
      socialMediaItems.push({
        icon: TwitterIcon,
        label: 'Twitter',
        url: socialMedias?.twitter,
      });
    socialMedias?.linkedIn &&
      socialMediaItems.push({
        icon: LinkedInIcon,
        label: 'LinkedIn',
        url: socialMedias?.linkedIn,
      });

    return [
      {
        name: 'Follow Us',
        items: socialMediaItems,
      },
    ].concat(categories);
  }, [socialMedias, categories]);

  const collapsed = useMediaQuery(theme => theme.breakpoints.down('md'));
  const { sx } = others;

  return (
    <SectionWrapper
      {...others}
      outerSx={{ bgcolor: '#f4f6f7', ...sx }}
      innerStackProps={{
        direction: collapsed ? 'column' : 'row',
        justifyContent: 'space-between',
      }}
    >
      {linkCategories.map(({ name, items }) => (
        <Box
          key={name}
          sx={{ bgcolor: 'transparent' }}
          {...(collapsed
            ? {
                component: Accordion,
                disableGutters: true,
                elevation: 0,
                square: true,
              }
            : {})}
        >
          <Box
            sx={{ px: 0, mb: collapsed ? 0 : 4 }}
            {...(collapsed
              ? {
                  component: AccordionSummary,
                  expandIcon: <ExpandIcon />,
                }
              : {})}
          >
            <Typography
              sx={{
                fontWeight: 600,
                lineHeight: 1,
                fontSize: '1.125rem',
                color: 'primary.main',
              }}
            >
              {name}
            </Typography>
          </Box>
          <Box
            {...(collapsed ? { component: AccordionDetails } : {})}
            sx={{ px: 0 }}
          >
            <Stack spacing={1.5}>
              {Array.isArray(items) &&
                items.map(({ icon, label, url }) => (
                  <Stack
                    key={label}
                    component={Link}
                    direction='row'
                    alignItems='center'
                    spacing={1}
                    href={url}
                    target='_blank'
                    sx={{
                      textDecoration: 'none',
                      color: 'text.main',
                      transition: '0.2s',
                      '&:hover': { color: 'accentColors.iris' },
                    }}
                  >
                    {icon && (
                      <Box
                        component={icon}
                        sx={{ fontSize: '1.4rem', color: 'inherit' }}
                      />
                    )}
                    <Typography sx={{ fontSize: '0.875rem', color: 'inherit' }}>
                      {label || ''}
                    </Typography>
                  </Stack>
                ))}
            </Stack>
          </Box>
        </Box>
      ))}
    </SectionWrapper>
  );
}

LinksHub.propTypes = {
  socialMedias: PropTypes.shape({
    facebook: PropTypes.string,
    linkedIn: PropTypes.string,
    instagram: PropTypes.string,
    twitter: PropTypes.string,
  }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      items: PropTypes.arrayOf(LinkPropType),
    })
  ),
};
