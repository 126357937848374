const COLOR_SCHEMES = Object.freeze({
  DARK: 'dark',
  LIGHT: 'light',
});

const LINK_TARGETS = Object.freeze({
  SELF: '_self',
  BLANK: '_blank',
});

const WP_URLS = Object.freeze({
  LOGIN: '/wp-login.php',
  LOGOUT: '/wp-login.php?action=logout&redirect_to=/',
});

export { COLOR_SCHEMES, LINK_TARGETS, WP_URLS };
