const linksHub = {
  socialMedias: {
    facebook: '#',
    twitter: '#',
    linkedIn: '#',
  },
  categories: [
    {
      name: 'Learn and Innovate',
      items: [
        {
          label: 'Autodesk Forge',
          url: 'https://forge.autodesk.com',
        },
        {
          label: 'Technology Centers',
          url: 'https://www.autodesk.com/technology-centers',
        },
        {
          label: 'Autodesk University',
          url: 'https://www.autodesk.com/autodesk-university/',
        },
        {
          label: 'Autodesk Consulting',
          url: 'https://customersuccess.autodesk.com/consulting',
        },
        {
          label: 'Certification',
          url: 'https://www.autodesk.com/certification/overview',
        },
      ],
    },
    {
      name: 'Autodesk',
      items: [
        {
          label: 'About Autodesk',
          url: 'https://www.autodesk.com/company',
        },
        {
          label: 'Careers',
          url: 'https://www.autodesk.com/careers/overview',
        },
        {
          label: 'Diversity and Belonging at Autodesk',
          url: 'https://www.autodesk.com/company/diversity-and-belonging',
        },
        {
          label: 'Products',
          url: 'https://www.autodesk.com/products',
        },
      ],
    },
  ],
};

export { linksHub };
