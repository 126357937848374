import PropTypes from 'prop-types';

const RepoPropType = PropTypes.shape({
  name: PropTypes.string,
  category: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  blogUrl: PropTypes.string,
  thumb: PropTypes.string,
});

export { RepoPropType };
