import { useState, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Stack,
  Box,
  MenuItem,
  Link as MuiLink,
  Drawer,
  Button,
  useMediaQuery,
} from '@mui/material';
import {
  MenuSharp as MenuIcon,
  CloseSharp as CloseIcon,
} from '@mui/icons-material';

import { LinkPropType } from '../propTypes';
import Logo from './Logo';

const menuItemStyle = {
  typography: 'body2',
  fontWeight: 600,
  borderBottom: 0,
  bgcolor: 'transparent',
  height: '2.5rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  transition: '0.1s',
  borderRadius: '4px',
  '&:hover': {
    bgcolor: 'hoverColors.dark',
  },
  '& > div': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
};

function Item({ link, ...others }) {
  const { external, target, url, label } = link;
  const linkProps = useMemo(
    () =>
      external
        ? {
            component: MuiLink,
            rel: 'noreferrer noopener',
            target: target || '_blank',
            href: url,
          }
        : { component: RouterLink, to: url },
    [external, target, url]
  );
  return (
    <MenuItem {...others} {...linkProps}>
      <Box component='div'>{label}</Box>
    </MenuItem>
  );
}

Item.propTypes = {
  link: LinkPropType.isRequired,
};

function Menus({ items, ...others }) {
  const drawerMenu = useMediaQuery(theme => theme.breakpoints.down('md'));
  const [drawerMenuOpen, setDrawerMenuOpen] = useState(false);
  const toggleDrawerMenu = () => {
    setDrawerMenuOpen(prevOpen => !prevOpen);
  };
  const closeDrawerMenu = () => {
    setDrawerMenuOpen(false);
  };

  const { sx } = others;

  return (
    <>
      <Stack direction='row' alignItems='center' sx={sx}>
        {drawerMenu ? (
          <MenuItem sx={menuItemStyle} onClick={toggleDrawerMenu}>
            <MenuIcon />
          </MenuItem>
        ) : (
          Array.isArray(items) &&
          items.map(item => (
            <Item link={item} key={item.label} sx={menuItemStyle} />
          ))
        )}
      </Stack>
      <Drawer anchor='left' open={drawerMenuOpen} onClose={closeDrawerMenu}>
        <Stack
          alignItems='flex-start'
          sx={{ minWidth: '20rem', height: '100%' }}
        >
          <Stack
            direction='row'
            alignItems='center'
            sx={{
              alignSelf: 'stretch',
              boxSizing: 'border-box',
              minHeight: theme => theme.siteSpecs.header.minHeight,
              borderBottom: theme => `1px solid ${theme.palette.lightSlate}`,
              px: 2,
            }}
          >
            <Logo />
          </Stack>
          <Stack
            alignItems='stretch'
            sx={{ alignSelf: 'stretch', flexGrow: 1 }}
          >
            {Array.isArray(items) &&
              items.map(item => (
                <Item
                  key={item.label}
                  link={item}
                  sx={{
                    ...menuItemStyle,
                    '&:hover': {
                      bgcolor: theme => theme.palette.hoverColors.light,
                    },
                  }}
                />
              ))}
          </Stack>
          <Button
            variant='outlined'
            onClick={closeDrawerMenu}
            sx={{ alignSelf: 'flex-end', m: 2 }}
            startIcon={<CloseIcon />}
          >
            Close
          </Button>
        </Stack>
      </Drawer>
    </>
  );
}

Menus.propTypes = {
  items: PropTypes.arrayOf(LinkPropType),
};

export default Menus;
export { Item };
