import { menus } from './menus';
import { banner } from './banner';
import { allRepoUrl, sectionTitle, highlights } from './repos';
import { blogs } from './blogs';
import { engineers } from './engineers';
import { codeOfConduct } from './codeOfConduct';
import { communities } from './communities';
import { linksHub } from './linksHub';
import { contributionProjects } from './contribution-projects';

const repos = {
  allRepoUrl,
  highlights,
  sectionTitle,
};

const payload = {
  general: {
    siteName: 'Open Source - Local',
    siteUrl: 'localhost:5001',
    siteAlert: {
      enabled: true,
      type: 'critical',
      content: 'The site is currently running on local debug mode.',
    },
  },
  header: {
    menus,
  },
  banner,
  repos,
  contributionProjects: {
    sectionTitle: 'Autodesk Open Source Contributions',
    items: contributionProjects,
  },
  blogs,
  engineers,
  codeOfConduct,
  communities,
  linksHub,
};

export default payload;
export { banner, repos, blogs, codeOfConduct, communities, linksHub, menus };
