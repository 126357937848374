import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';

import SectionWrapper from './SectionWrapper';

const ALERT_TYPES = Object.freeze({
  CRITICAL: 'critical',
  NORMAL: 'normal',
});

export default function AlertBar({ type, content, ...others }) {
  const { sx } = others;

  return (
    <SectionWrapper
      outerSx={{
        bgcolor:
          type === ALERT_TYPES.CRITICAL
            ? 'accentColors.clay'
            : 'accentColors.iris',
        color: 'parentWhite',
        ...sx,
      }}
      innerStackProps={{
        direction: 'row',
        alignItems: 'center',
        spacing: 1,
      }}
      innerSx={{
        py: 1.5,
        'a, a:hover, a:visited': {
          color: 'parentWhite',
        },
      }}
    >
      <InfoIcon />
      <Typography dangerouslySetInnerHTML={{ __html: content || '' }} />
    </SectionWrapper>
  );
}

AlertBar.propTypes = {
  type: PropTypes.oneOf(Object.values(ALERT_TYPES)),
  content: PropTypes.string,
};

AlertBar.defaultProps = {
  type: ALERT_TYPES.NORMAL,
  content: '',
};

export { ALERT_TYPES };
