const baseConfig = {
  general: {
    siteName: 'Open Source',
    siteUrl: 'https://opensource.autodesk.com',
    siteAlert: {
      enabled: false,
      type: 'critical',
      content: '',
    },
  },
  header: {
    menus: {
      primary: [],
    },
  },
  banner: {
    tagline: 'Open',
    content: 'Welcome',
    links: [
      {
        label: 'Code of Conduct',
        url: '#code-of-conduct',
        external: true,
        target: '_self',
      },
    ],
  },
  repos: {
    allRepoUrl: '',
    highlights: [],
  },
  contributionProjects: {
    items: [],
  },
  blogs: {
    blogs: new Array(9).fill({
      thumb: '',
      topic: '',
      author: {
        displayName: '',
        avatar: '',
        jobTitle: '',
      },
      summary: '',
      url: '',
      date: '',
    }),
  },
  engineers: {
    items: [],
  },
  codeOfConduct: {
    title: 'Code of Conduct',
    content: '',
    faq: '',
  },
  communities: {
    title: 'We Support and Invest in the Open Source Community',
    textContent:
      'Autodesk is a member and contributor of the following organizations​',
    items: [
      {
        name: 'The Linux Foundation',
        logoUrl:
          'https://www.linuxfoundation.org/wp-content/uploads/lf_logo.svg',
        url: 'https://www.linuxfoundation.org/',
      },
    ],
  },
  linksHub: {
    socialMedias: {
      facebook: '#',
      twitter: '#',
      linkedIn: '#',
      instagram: '#',
    },
    categories: [
      {
        name: 'Autodesk',
        items: [
          {
            label: 'About Autodesk',
            url: 'https://www.autodesk.com/company',
          },
        ],
      },
    ],
  },
  footer: {
    links: [
      {
        label: 'Privacy/Cookies',
        url: '/',
        external: true,
        target: '_blank',
      },
    ],
  },
};

export default baseConfig;
export { baseConfig };
