import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Stack, Divider } from '@mui/material';

import { SiteInfoContext } from '../contexts';
import SectionWrapper from './SectionWrapper';

const linkStyle = {
  color: 'inherit',
  textDecoration: 'none',
  cursor: 'pointer',
  '&:hover': {
    color: 'inherit',
    textDecoration: 'underline',
  },
  '&:visited': {
    color: 'inherit',
  },
};

export default function Footer({ ...others }) {
  const { currentDateTime, footer = {} } = useContext(SiteInfoContext);
  const { sx } = others;

  const handleTealiumLinkClick = linkId => {
    try {
      window.document.getElementById(linkId)?.click();
    } catch {}
  };

  return (
    <SectionWrapper
      outerSx={{
        bgcolor: 'parentWhite',
        borderTopWidth: '1px',
        borderTopColor: 'divider',
        borderTopStyle: 'solid',
        ...sx,
      }}
      innerSx={{
        py: 2,
        fontSize: '0.875rem',
        color: 'parentBlack',
      }}
      innerStackProps={{
        direction: 'row',
        alignItems: 'center',
        justifyContent: { xs: 'flex-start', md: 'flex-end' },
      }}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={{ xs: 0.5, md: 2 }}
        divider={<Divider orientation='vertical' flexItem />}
      >
        <Box
          component='a'
          sx={linkStyle}
          onClick={() => {
            handleTealiumLinkClick('tealium-privacy-settings');
          }}
        >
          Privacy settings
        </Box>
        <Box
          component='a'
          sx={linkStyle}
          onClick={() => {
            handleTealiumLinkClick('tealium-do-not-sell');
          }}
        >
          Do not sell or share my personal data
        </Box>
        {Array.isArray(footer.links) &&
          footer.links.map(({ label, url, external, target }) => (
            <Box
              component={external ? 'a' : Link}
              target={target || '_blank'}
              href={url}
              sx={linkStyle}
              key={label || url}
            >
              {label}
            </Box>
          ))}
        <Typography fontSize='inherit'>
          &copy; {currentDateTime?.getFullYear() || ''} Autodesk Inc. All rights
          reserved
        </Typography>
      </Stack>
    </SectionWrapper>
  );
}
