import blogThumb1 from './thumb1.png';
import avatar1 from './raji-avatar.png';
import avatar2 from './avatar-2.jpeg';
import avatar3 from './lida.jpeg';
import avatar4 from './david.jpeg';
import avatar5 from './alexis.jpeg';

const blogs = {
  blogs: [
    {
      thumb: blogThumb1,
      topic: `Open Source Community Accelerates Innovation for Customers`,
      authors: [
        {
          displayName: 'Raji Arasu',
          avatar: avatar1,
          jobTitle: 'Autodesk CTO',
        },
      ],
      summary: `An interview with Raji Arasu, Autodesk's CTO, on her thoughts about open source and how working as a community, we can make big impact for our customers at global scale.`,
      url: 'https://test.autodesk.com/blog1',
      date: 'October 1, 2021',
    },
    {
      thumb: blogThumb1,
      topic: 'Open Source in the Entertainment Industry',
      authors: [
        {
          displayName: 'Anuj Chaudhary',
          jobTitle: 'Sr. SW Architect',
          avatar: avatar2,
        },
      ],
      summary:
        'A work-in-progress Doug Out of town, next week Was at ASWF TAC & ACES CLF meetings Luster work Patrick Apply with...with bit depth is a work-in-progress Code Reviews Was at ASWF TAC & ACES CLF meetings',
      url: 'https://test.autodesk.com/blog2',
      date: 'May 16, 2021',
    },
    {
      thumb: blogThumb1,
      topic: 'Open Source Unlocks a Better Version of Ourselves',
      authors: [
        {
          displayName: 'Daniel Li',
          jobTitle: 'VR Team Lead',
          avatar: avatar3,
        },
      ],
      summary:
        'Live Design VR Experience of a San Francisco Apartment using the HTC Vive, shown at AU 2015',
      url: 'https://test.autodesk.com/blog4',
      date: 'April 20, 2021',
    },
    {
      thumb: blogThumb1,
      topic: 'Open Source in the Entertainment Industry',
      authors: [
        {
          displayName: 'Anuj Chaudhary',
          jobTitle: 'Sr. SW Architect',
          avatar: avatar2,
        },
        {
          displayName: 'Ives Bai',
          jobTitle: 'Sr. SW Engineer',
          avatar: avatar3,
        },
      ],
      summary:
        'A work-in-progress Doug Out of town, next week Was at ASWF TAC & ACES CLF meetings Luster work Patrick Apply with...with bit depth is a work-in-progress Code Reviews Was at ASWF TAC & ACES CLF meetings',
      url: 'https://test.autodesk.com/blog2',
      date: 'May 16, 2021',
    },
    {
      thumb: blogThumb1,
      topic: 'Open Source Unlocks a Better Version of Ourselves',
      authors: [
        {
          displayName: 'Daniel Li 1',
          jobTitle: 'CEO',
          avatar: avatar3,
        },
        {
          displayName: 'Daniel Li 2',
          jobTitle: 'CFO',
          avatar: avatar3,
        },
        {
          displayName: 'Daniel Li 3',
          jobTitle: 'CHO',
          avatar: avatar3,
        },
        {
          displayName: 'Daniel Li 4',
          jobTitle: 'CTO',
          avatar: avatar3,
        },
        {
          displayName: 'Daniel Li 5',
          jobTitle: 'CBO',
          avatar: avatar3,
        },
      ],
      summary:
        'Live Design VR Experience of a San Francisco Apartment using the HTC Vive, shown at AU 2015',
      url: 'https://test.autodesk.com/blog4',
      date: 'April 20, 2021',
    },
    {
      thumb: '',
      topic: 'How an innovation sprint led to a Fusion 360 post-debugger',
      authors: [
        {
          displayName: 'David Maffioli',
          avatar: avatar4,
        },
      ],
      summary: '',
      url:
        'https://forums.autodesk.com/t5/engineering-hub-blog/how-an-innovation-sprint-led-to-a-fusion-360-post-debugger/ba-p/11492811',
      date: 'October 29, 2022',
    },
    {
      thumb: '',
      topic: 'Security best practices in open source',
      authors: [
        {
          displayName: 'Alexis Womble',
          avatar: avatar5,
        },
      ],
      summary: '',
      url:
        'https://forums.autodesk.com/t5/engineering-hub-blog/security-best-practices-in-open-source/ba-p/11494591',
      date: 'October 29, 2022',
    },
    {
      thumb: '',
      topic: 'What makes a good open-source citizen and contributor?',
      authors: [
        {
          displayName: 'David Maffioli',
          avatar: avatar4,
        },
      ],
      summary: '',
      url:
        'https://forums.autodesk.com/t5/engineering-hub-blog/how-an-innovation-sprint-led-to-a-fusion-360-post-debugger/ba-p/11492811',
      date: 'October 29, 2022',
    },
    {
      thumb: '',
      topic: 'Security best practices in open source2',
      authors: [
        {
          displayName: 'Alexis Womble',
          avatar: avatar5,
        },
      ],
      summary: '',
      url:
        'https://forums.autodesk.com/t5/engineering-hub-blog/security-best-practices-in-open-source/ba-p/11494591',
      date: 'October 29, 2022',
    },
  ],
};

export { blogs };
