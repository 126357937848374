const highlights = [
  {
    name: 'Maya USD',
    category: 'Media & Entertainment',
    description:
      'A common USD (Universal Scene Description) plugin for Autodesk Maya',
    links: [
      {
        label: 'View Project',
        url: 'https://github.com/Autodesk/maya-usd',
      },
      {
        label: 'Blog',
        url: 'https://github.com/Autodesk/maya-usd',
      },
    ],
  },
  {
    name: 'Arnold USD',
    category: 'Media & Entertainment',
    description: 'Arnold components for USD',
    links: [
      {
        label: 'View Project',
        url: 'https://github.com/Autodesk/arnold-usd',
      },
    ],
  },

  {
    name: 'React Base Table',
    category: 'Front End',
    description:
      'A react table component to display large datasets with high performance and flexibility',
    links: [
      {
        label: 'View Project',
        url: 'https://github.com/Autodesk/react-base-table',
      },
    ],
  },
  {
    name: 'HIG',
    category: 'Front End',
    description: "Autodesk's unified design system",
    links: [
      {
        label: 'View Project',
        url: 'https://github.com/Autodesk/maya-usd',
      },
    ],
  },
  {
    name: 'DynamoDS',
    category: 'Generative Design',
    description: 'Open Source Graphical Programming for Design',
    links: [
      {
        label: 'View Project',
        url: 'https://github.com/Autodesk/react-base-table',
      },
    ],
  },
  {
    name: 'LibrarieJS',
    category: 'Generative Design',
    description: 'Reusable library component',
    links: [
      {
        label: 'View Project',
        url: 'https://github.com/DynamoDS/librarie.js',
      },
      {
        label: 'View Project',
        url: 'https://github.com/DynamoDS/librarie.js',
      },
    ],
  },
];

const allRepoUrl = 'https://github.com/Autodesk';
const sectionTitle = 'Featured Projects';

export { allRepoUrl, highlights, sectionTitle };
