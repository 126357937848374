import PropTypes from 'prop-types';
import { LINK_TARGETS } from '../consts';

const LinkPropType = PropTypes.shape({
  label: PropTypes.string,
  url: PropTypes.string,
  target: PropTypes.oneOf(Object.values(LINK_TARGETS)),
  external: PropTypes.bool,
});

export { LinkPropType };
