import { lazy } from 'react';

const Home = lazy(() => import('./pages/Home'));
const Admin = lazy(() => import('./pages/Admin'));

const routers = {
  home: {
    path: '/',
    Component: Home,
    exact: true,
  },
  admin: {
    path: '/admin',
    Component: Admin,
  },
};

export default routers;
export { routers };
