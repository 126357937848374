import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import '@digital-hig/theme-data/lib/build/css/dhig-fonts.css';

import App from './App';

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
