import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink, Box, SvgIcon } from '@mui/material';

import { COLOR_SCHEMES } from '../consts';

const useStyles = props =>
  useMemo(
    () => ({
      wrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: '1rem',
        fontWeight: props.arrow ? 600 : 400,
        textDecoration: 'none',
        outline: 0,
        boxShadow: 'none',
        color:
          props.colorScheme === COLOR_SCHEMES.DARK
            ? 'parentWhite'
            : 'parentBlack',
        cursor: 'pointer',
        '& > .icon-arrow-circle': {
          display: 'none',
        },
        '& > .icon-arrow-circle-line': {
          display: 'block',
        },
        '&:hover > .icon-arrow-circle': {
          display: 'block',
        },
        '&:hover > .icon-arrow-circle-line': {
          display: 'none',
        },

        '& > div': {
          outline: 0,
          textDecoration: 'none',
        },
        '&:hover > div': {
          textDecoration: props.arrow ? 'none' : 'underline',
          textDecorationThickness: '2px',
          textDecorationColor: theme => theme.palette.accentColors.iris,
        },
        '&:focus > div': {
          textDecoration: props.arrow ? 'none' : 'underline',
          textDecorationThickness: '2px',
          textDecorationColor: theme => theme.palette.accentColors.plant,
        },
      },
    }),
    [props]
  );

export default function Link({
  url,
  target,
  external,
  children,
  arrow,
  icon,
  colorScheme,
  ...others
}) {
  const linkProps = useMemo(
    () =>
      external
        ? {
            component: MuiLink,
            rel: 'noreferrer noopener',
            target: target || '_blank',
            href: url,
          }
        : { component: RouterLink, to: url },
    [external, target, url]
  );

  const styles = useStyles({ colorScheme, arrow, icon });
  const { sx } = others;

  return (
    <Box {...others} sx={{ ...styles.wrapper, ...sx }} {...linkProps}>
      {arrow && (
        <>
          <SvgIcon className='icon-arrow-circle-line' viewBox='0 0 100 100'>
            <path d='M50 0a50 50 0 1 0 50 50A50.02 50.02 0 0 0 50 0zm0 92a42 42 0 1 1 42-42 42 42 0 0 1-42 42z'></path>
            <path d='M55 27.4L49.4 33l12.9 13H26.4v8h35.9L49.4 67l5.6 5.6L77.6 50z'></path>
          </SvgIcon>
          <SvgIcon className='icon-arrow-circle' viewBox='0 0 100 100'>
            <path d='M50 0a50 50 0 1 0 50 50A50.02 50.02 0 0 0 50 0zm27.6 50L55 72.6 49.4 67l13-13h-36v-8h35.9l-13-13 5.7-5.7L77.6 50z' />
          </SvgIcon>
        </>
      )}
      {!arrow && icon}
      <Box component='div' ml={arrow || icon ? 1.5 : 0}>
        {children}
      </Box>
    </Box>
  );
}

export { COLOR_SCHEMES };

Link.propTypes = {
  arrow: PropTypes.bool,
  icon: PropTypes.node,
  url: PropTypes.string,
  target: PropTypes.string,
  external: PropTypes.bool,
  children: PropTypes.node,
  colorScheme: PropTypes.oneOf(Object.values(COLOR_SCHEMES)),
};

Link.defaultProps = {
  colorScheme: COLOR_SCHEMES.DARK,
  external: true,
};
