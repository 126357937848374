import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';

const SectionWrapper = forwardRef(
  ({ outerSx, innerSx, innerStackProps, children, ...others }, ref) => {
    return (
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
        {...others}
        sx={{
          width: '100%',
          boxSizing: 'border-box',
          ...outerSx,
        }}
        ref={ref}
      >
        <Stack
          {...innerStackProps}
          sx={{
            py: { xs: 4, md: 8, xxl: 10 },
            px: { xs: 1, sm: 2, lg: 4 },
            flexGrow: 1,
            width: '100%',
            maxWidth: { xs: '1280px', xxl: '1600px' },
            boxSizing: 'border-box',
            ...innerSx,
          }}
        >
          {children}
        </Stack>
      </Stack>
    );
  }
);

SectionWrapper.propTypes = {
  outerSx: PropTypes.object,
  innerSx: PropTypes.object,
  innerStackProps: PropTypes.object,
  children: PropTypes.node,
};

export default SectionWrapper;
