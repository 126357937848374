import { useEffect, useState, useRef, Suspense } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';
import { SnackbarProvider } from 'notistack';

import { adskTheme } from './theme/adskTheme';
import { SiteInfoContext, CurrentUserContext } from './contexts';
import { getConfig, formatConfigData, getCurrentUser } from './api/site';
import { routers } from './routers';

import NotFound from './pages/NotFound';
import Header from './components/Header';
import Footer from './components/Footer';

export default function App() {
  const { pathname } = useLocation();
  const [siteInfo, setSiteInfo] = useState({});
  const [currentUser, setCurrentUser] = useState({ id: null });
  const headerEl = useRef(null);
  const [headerHeight, setHeaderHeight] = useState('auto');

  useEffect(() => {
    getConfig()
      .then(data => {
        setSiteInfo({ ...data });
      })
      .catch(e => {
        window.console.error(e);
      });
  }, []);

  useEffect(() => {
    getCurrentUser()
      .then(data => {
        setCurrentUser({
          id: null,
          avatar: null,
          displayName: '',
          email: '',
          account: '',
          role: '',
          ...data,
        });
      })
      .catch(e => {
        setCurrentUser({
          id: -1,
          avatar: null,
          displayName: '',
          email: '',
          account: '',
          role: '',
        });
      });
  }, []);

  /** TODO: function to track page view */
  useEffect(() => {
    try {
    } catch (e) {}
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => {
      headerEl?.current?.clientHeight &&
        setHeaderHeight(`${headerEl?.current?.clientHeight}px`);
    });
  }, [headerEl, siteInfo]);

  useEffect(() => {
    // To fix fragment link not working when we browse in the first time

    const handleLoad = () => {
      const hash = window.location.hash;
      const whiteList = ['#code-of-conduct'];

      if (whiteList.includes(hash) && document.getElementById(hash.slice(1))) {
        let a = document.createElement('a');
        a.href = hash;
        a.click();
        a = null;
      }
    };

    handleLoad();
    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, [pathname]);

  const handleConfigUpdated = newConfigData => {
    setSiteInfo(formatConfigData(newConfigData));
  };

  return (
    <SiteInfoContext.Provider value={siteInfo}>
      <CurrentUserContext.Provider value={currentUser}>
        <ThemeProvider theme={adskTheme}>
          <CssBaseline />
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Header ref={headerEl} sx={{ zIndex: adskTheme.zIndex.appBar }} />
            <Box pt={headerHeight}>
              <Suspense fallback={<div />}>
                <Switch>
                  {Object.entries(routers).map(
                    ([key, { path, Component, ...others }]) => (
                      <Route key={key} path={path} {...others}>
                        <Component onConfigUpdated={handleConfigUpdated} />
                      </Route>
                    )
                  )}
                  <Route>
                    <NotFound />
                  </Route>
                </Switch>
              </Suspense>
              <Footer />
            </Box>
          </SnackbarProvider>
        </ThemeProvider>
      </CurrentUserContext.Provider>
    </SiteInfoContext.Provider>
  );
}
