import { createTheme } from '@mui/material/styles';

const ADSK_COLORS = {
  parentBlack: '#000000',
  parentWhite: '#ffffff',
  darkSlate: '#666666',
  lightSlate: '#cccccc',
  accentColors: {
    clay: '#d74e26',
    plant: '#2bc275',
    iris: '#5f60ff',
    gold: '#ffc21a',
  },
};

const adskTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      html {
         -webkit-font-smoothing: antialiased;
       }
     `,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 2000,
    },
  },
  typography: {
    fontFamily: ['ArtifaktElement', 'Arial', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: ADSK_COLORS.parentBlack,
    },
    secondary: {
      main: ADSK_COLORS.accentColors.plant,
      contrastText: ADSK_COLORS.parentWhite,
    },
    iris: {
      main: ADSK_COLORS.accentColors.iris,
      contrastText: ADSK_COLORS.parentWhite,
    },
    background: {
      default: ADSK_COLORS.parentWhite,
    },
    error: {
      main: ADSK_COLORS.accentColors.clay,
    },
    divider: ADSK_COLORS.lightSlate,
    ...ADSK_COLORS,
    hoverColors: {
      dark: '#212121',
      light: '#efefef',
    },
  },
  siteSpecs: {
    header: {
      minHeight: '3.5rem',
    },
  },
});

adskTheme.typography.headline = {
  fontFamily: ['ArtifaktLegend', 'Arial', 'sans-serif'].join(','),
  fontWeight: 800,
  lineHeight: 1.2,
  fontSize: '1.75rem',
  [adskTheme.breakpoints.up('md')]: {
    fontSize: '2.125rem',
  },
  [adskTheme.breakpoints.up('lg')]: {
    fontSize: '2.75rem',
  },
};

adskTheme.typography.headline2 = {
  fontFamily: ['ArtifaktLegend', 'Arial', 'sans-serif'].join(','),
  lineHeight: 1.2,
  fontSize: '1.125rem',
  [adskTheme.breakpoints.up('sm')]: {
    fontSize: '1.25rem',
  },
  [adskTheme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
};

adskTheme.typography.headline2Lager = {
  fontFamily: ['ArtifaktLegend', 'Arial', 'sans-serif'].join(','),
  lineHeight: 1.2,
  fontSize: '1.5rem',
  [adskTheme.breakpoints.up('sm')]: {
    fontSize: '1.75rem',
  },
  [adskTheme.breakpoints.up('md')]: {
    fontSize: '2.25rem',
  },
};

adskTheme.typography.subHeadline = {
  fontSize: '1rem',
  lineHeight: 1.5,
  [adskTheme.breakpoints.up('md')]: {
    fontSize: '1.125rem',
  },
};

export default adskTheme;
export { adskTheme };
