import { Stack, Divider, Typography } from '@mui/material';

import Link, { COLOR_SCHEMES } from './Link';
import SectionWrapper from './SectionWrapper';

import bgImage from '../assets/images/error-404.webp';

export default function NotFound({ ...others }) {
  const { sx } = others;

  return (
    <SectionWrapper
      outerSx={sx}
      innerSx={{
        minHeight: '750px',
        background: {
          xs: `url(${bgImage}) no-repeat calc(50% + 5px) calc(50% + 150px) /auto`,
          md: `url(${bgImage}) no-repeat calc(50% + 100px) calc(50% + 200px) /auto`,
          lg: `url(${bgImage}) no-repeat calc(50% + 230px) calc(50%) /auto`,
          xxl: `url(${bgImage}) no-repeat calc(50% + 330px) calc(50%) /auto`,
        },
      }}
    >
      <Stack sx={{ maxWidth: { sm: '37.5rem', xxl: '50rem' } }}>
        <Typography>404 error</Typography>
        <Divider sx={{ my: 1.5 }} />
        <Typography typography='headline2' mt={4}>
          Page not found.
        </Typography>
        <Typography mt={2}>
          Sorry we cannot find the page, please go back to Autodesk Open Source
          home page.
        </Typography>
        <Stack mt={4}>
          <Link
            arrow
            url='/'
            external={false}
            colorScheme={COLOR_SCHEMES.LIGHT}
          >
            Go to Autodesk Open Source home
          </Link>
        </Stack>
      </Stack>
    </SectionWrapper>
  );
}
